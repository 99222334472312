import { Contract } from '@injectivelabs/sdk-ts'
import { TokenType } from '@injectivelabs/token-metadata'
import { Asset } from '@/types'

export class UIContractTransformer {
  static getAmount(balances: { address: string; amount: string }[]) {
    return balances
      .reduce(
        (finalAmount: number, { amount }: { amount: string }) =>
          finalAmount + Number(amount),
        0
      )
      .toString()
  }

  static contractToAssetContract(contract: Contract): Asset {
    const { decimals = 0, symbol = '', name } = contract.initMessage || {}
    const initialBalances =
      contract.initMessage && contract.initMessage.initial_balances
        ? contract.initMessage.initial_balances
        : [{ address: '', amount: '0' }]

    const amount = UIContractTransformer.getAmount(initialBalances)
    const denom = contract.address

    return {
      denom,
      amount,
      contractAddress: contract.address,
      token: {
        denom,
        symbol,
        decimals,
        name: name || contract.label,
        logo: '',
        coinGeckoId: '',
        tokenType: TokenType.Cw20,

        cw20: {
          decimals,
          address: contract.address,
          tokenType: TokenType.Cw20
        }
      }
    }
  }
}
