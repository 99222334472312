import { defineStore } from 'pinia'
import { Contract, ContractTransaction } from '@injectivelabs/sdk-ts'
import { indexerRestExplorerApi, tokenService, wasmApi } from '@/app/Services'
import { ContractNotFoundException } from '@/app/exceptions/ContractNotFoundException'
import { UIContractTransformer } from '@/app/client/transformers/UIContractTransformer'
import { Asset, ContractBalanceWithToken } from '@/types'

type ContractStoreState = {
  assetContract?: Asset
  assetContracts: Asset[]
  contract?: Contract
  cw20AccountBalancesWithToken: ContractBalanceWithToken[]
  cw20AccountBalancesTotalCount: number
  contracts: Contract[]
  totalCount: number
  transactions: ContractTransaction[]
  transactionsTotalCount: number
  transactionsPivotCount: number
}

const initialStateFactory = () => ({
  assetContract: undefined,
  assetContracts: [],
  contract: undefined,
  cw20AccountBalancesWithToken: [],
  cw20AccountBalancesTotalCount: 0,
  contracts: [],
  totalCount: 0,
  transactions: [],
  transactionsTotalCount: 0,
  transactionsPivotCount: 0
})

export const useContractStore = defineStore('contract', {
  state: (): ContractStoreState => initialStateFactory(),
  actions: {
    async fetchContractsTotalCount() {
      const contractStore = useContractStore()

      const { paging } = await indexerRestExplorerApi.fetchContracts({
        limit: 1
      })

      contractStore.$patch({
        totalCount: paging.total
      })
    },

    async fetchContracts({
      assetsOnly,
      limit,
      skip,
      updateTotalCount
    }: {
      assetsOnly?: boolean
      limit: number
      skip?: number
      updateTotalCount: boolean
    }) {
      const contractStore = useContractStore()

      const { contracts, paging } = await indexerRestExplorerApi.fetchContracts(
        {
          skip,
          limit,
          assetsOnly
        }
      )

      const assetContracts = contracts.map(
        UIContractTransformer.contractToAssetContract
      )

      if (updateTotalCount) {
        contractStore.$patch({
          totalCount: paging.total
        })
      }

      contractStore.$patch({
        assetContracts,
        contracts
      })
    },

    async fetchContract(contractAddress: string) {
      const contractStore = useContractStore()
      const appStore = useAppStore()

      try {
        const contract = await indexerRestExplorerApi.fetchContract(
          contractAddress
        )
        const assetContract =
          UIContractTransformer.contractToAssetContract(contract)

        contractStore.$patch({
          assetContract,
          contract
        })
      } catch (e: any) {
        if (e.message.includes(404) || e.message.includes(400)) {
          appStore.$patch({
            searchParam: contractAddress
          })

          throw new ContractNotFoundException()
        }

        throw e
      }
    },

    async fetchCW20AccountBalancesTotalCount(contractAddress: string) {
      const contractStore = useContractStore()

      const { contractAccountsBalance, pagination } =
        await wasmApi.fetchContractAccountsBalance({
          contractAddress
        })

      const totalCount = Math.min(
        contractAccountsBalance.length,
        pagination?.total || 0
      )

      contractStore.$patch({
        cw20AccountBalancesTotalCount: totalCount
      })
    },

    async fetchCW20AccountBalancesWithToken({
      contractAddress,
      limit,
      offset
    }: {
      contractAddress: string
      limit?: number
      offset?: number
    }) {
      const contractStore = useContractStore()

      const { contractAccountsBalance } =
        await wasmApi.fetchContractAccountsBalance({
          contractAddress,
          pagination: {
            key: '',
            limit,
            offset
          }
        })

      const cw20AccountBalancesWithToken =
        await tokenService.toContractCw20BalancesWithToken({
          contractAccountsBalance,
          contractAddress
        })

      contractStore.$patch({
        cw20AccountBalancesWithToken
      })
    },

    async fetchContractTransactions({
      contractAddress,
      limit,
      skip,
      updateTotalCount
    }: {
      contractAddress: string
      limit: number
      skip?: number
      updateTotalCount: boolean
    }) {
      const contractStore = useContractStore()

      const { transactions, paging } =
        await indexerRestExplorerApi.fetchContractTransactions({
          contractAddress,
          params: {
            limit,
            skip
          }
        })

      if (updateTotalCount) {
        contractStore.$patch({
          transactionsTotalCount: paging.total,
          transactionsPivotCount: paging.to
        })
      }

      contractStore.$patch({
        transactions
      })
    },

    reset() {
      useContractStore().$reset()
    }
  }
})
